import React, { useState } from 'react';
import styles from './XiucaiIntro.module.css';

const XiucaiIntro = () => {
  const [expandedSections, setExpandedSections] = useState({
    endRhyming: false,
    cosmologicalRhyming: false,
    thematicProgression: false,
    exampleBreakdown: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className={styles.xiucaiIntroContainer}>
      <h2 className={styles.heading}>Xiucai Degree</h2>

      {/* Static Introduction Section */}
      <p className={styles.introText}>
        Congratulations, you have reached the second level of the Examination Path, the Xiucai Degree (Budding Talent).
      </p>

      <p className={styles.introText}>
      While the first level of the exam provides you with the tools to create the first couplet of an unregulated jueju, which establishes a natural imagistic scene (known as "jing"), this second level exam (Budding Talent) will provide you with the knowledge to write the second couplet (lines three and four), which introduces the "qing" 情 or the emotion, ideas, and other aspects of our human experience.
      </p>

      {/* Rhyming Section: End Rhyming and Cosmological Rhyming */}
      <div className={styles.ruleSection}>
        <h3 className={styles.subHeading}>Five Levels of Rhyming</h3>
        <p className={styles.detailText}>
        Rhyming in Jueju poetry builds progressively from one level to the next. In this section, we’ll revisit end rhyming from the first level and introduce the next level, “Cosmological Rhyming”.
        </p>
	<iframe width="560" height="315" 
        src="https://www.youtube.com/embed/b3TTxQVMkuc" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
        </iframe>
        <br></br>
        {/* End Rhyming Section */}
        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('endRhyming')} className={styles.sectionTitle}>
            End Rhyming {expandedSections.endRhyming ? '▲' : '▼'}
          </h4>
          {expandedSections.endRhyming && (
            <div className={styles.sectionContent}>
              <p>
                Poets must rhyme the last words in the first, second, and fourth lines (an AABA rhyme scheme).
              </p>
            </div>
          )}
        </div>

        {/* Cosmological Rhyming Section */}
        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('cosmologicalRhyming')} className={styles.sectionTitle}>
            Cosmological/Spiritual Rhyming {expandedSections.cosmologicalRhyming ? '▲' : '▼'}
          </h4>
          {expandedSections.cosmologicalRhyming && (
            <div className={styles.sectionContent}>
              <p>
              A poet must discover the inter-resonance of nature and human consciousness (feelings, emotions, ideas, etc.) and disclose this in a relationship between the poem’s two couplets.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Expandable Thematic Progression Section */}
      <div className={styles.expandableSection}>
        <h3 onClick={() => toggleSection('thematicProgression')} className={styles.sectionTitle}>
          📚 Thematic Progression {expandedSections.thematicProgression ? '▲' : '▼'}
        </h3>
        {expandedSections.thematicProgression && (
          <div className={styles.sectionContent}>
            <p>
            By the end, therefore, you will learn to write all four lines of an unregulated jueju by following the traditional thematic progression:
            </p>
            <ul className={styles.progressionList}>
              <li><strong>A起 (qi)</strong> – Line one introduces the external scene.</li>
              <li><strong>B承 (cheng)</strong> – Line two deepens and extends the external scene.</li>
              <li><strong>C转 (zhuan)</strong> – Line three turns the poem inward toward emotional resonance.</li>
              <li><strong>D合 (he)</strong> – Line four unifies and resolves the poem, bringing it to a close, revealing a deeper frame of reference where the external scene in the first couplet and internal feelings introduced in the second couplet are revealed to be inter-resonant extensions of one another.</li>
            </ul>
          </div>
        )}
      </div>

      {/* Expandable Example Breakdown Section with Enhanced Styling */}
      <div className={styles.expandableSection}>
        <h3 onClick={() => toggleSection('exampleBreakdown')} className={styles.sectionTitle}>
          📝 Example Breakdown {expandedSections.exampleBreakdown ? '▲' : '▼'}
        </h3>
        {expandedSections.exampleBreakdown && (
          <div className={styles.sectionContent}>
            <p>Example:</p>
            <div className={styles.exampleSection}>
              <h4>1. 起 (qi) – First Line: "Introduce scene"</h4>
              <p className={styles.exampleLine}><em>White stones&nbsp;&nbsp;&nbsp;&nbsp; soft breeze&nbsp;&nbsp;&nbsp;&nbsp; cold stream flows</em></p>

              <h4>2. 承 (cheng) – Second Line: "Deepen scene"</h4>
              <p className={styles.exampleLine}><em>Wet bank&nbsp;&nbsp;&nbsp;&nbsp; birds sing&nbsp;&nbsp;&nbsp;&nbsp; tall grass blows</em></p>

              <h4>3. 转 (zhuan) – Third Line: "Turning Line"</h4>
              <p className={styles.exampleLine}><em>Breathe in&nbsp;&nbsp;&nbsp;&nbsp; thoughts fade&nbsp;&nbsp;&nbsp;&nbsp; cast your line</em></p>

              <h4>4. 合 (he) – Fourth Line: "Unifying Line"</h4>
              <p className={styles.exampleLine}><em>No fish &nbsp;&nbsp;&nbsp;&nbsp;hours pass&nbsp;&nbsp;&nbsp;&nbsp; wide mind slows</em></p>
            </div>
            <p className={styles.conclusionText}>
            The final quatrain reveals a single holistic scene that blends the natural world (jing) and human emotions (qing) into an inter-resonant whole:
            </p>
            <div className={styles.finalQuatrain}>
              <p><em>White stones&nbsp;&nbsp;&nbsp;&nbsp; soft breeze&nbsp;&nbsp;&nbsp;&nbsp; cold stream flows</em></p>
              <p><em>Wet bank&nbsp;&nbsp;&nbsp;&nbsp;birds sing&nbsp;&nbsp;&nbsp;&nbsp;tall grass blows</em></p>
              <p><em>Breathe in&nbsp;&nbsp;&nbsp;&nbsp; thoughts fade&nbsp;&nbsp;&nbsp;&nbsp; cast your line</em></p>
              <p><em>No fish&nbsp;&nbsp;&nbsp;&nbsp; hours pass&nbsp;&nbsp;&nbsp;&nbsp; wide mind slows</em></p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default XiucaiIntro;

