import React, { useState, useRef, useEffect } from 'react';
import SemanticStyles from './Semantic.module.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemTypes = {
  UNIT: 'unit',
};

const MatchingUnit = ({ id, text, type, handleDrop }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.UNIT,
    drop: (item) => handleDrop(item.id, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.UNIT,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drop} className={`${SemanticStyles.matchingUnit} ${type}`}>
      {type === 'left' ? <span>{text}</span> : null}
      <div ref={drag} className={`${SemanticStyles.diamond} ${isDragging ? SemanticStyles.dragging : ''}`} id={`dot-${id}`} />
      {type === 'right' ? <span>{text}</span> : null}
      {isOver && canDrop && <div className={SemanticStyles.highlight} />}
    </div>
  );
};
// Helper function to calculate line positions
const calculateLinePositions = (matches, linesContainerRef) => {
  const newLines = matches.map((match) => {
    const from = document.getElementById(`dot-${match.from}`);
    const to = document.getElementById(`dot-${match.to}`);
    if (from && to) {
      const fromRect = from.getBoundingClientRect();
      const toRect = to.getBoundingClientRect();
      const containerRect = linesContainerRef.current.getBoundingClientRect();
      const x1 = fromRect.right - containerRect.left;
      const y1 = fromRect.top + fromRect.height / 2 - containerRect.top;
      const x2 = toRect.left - containerRect.left;
      const y2 = toRect.top + toRect.height / 2 - containerRect.top;
      return { x1, y1, x2, y2 };
    }
    return null;
  }).filter(line => line !== null);
  return newLines;
};

// Two-Word Matching Section
const TwoWordMatchingSection = () => {
  const [matches, setMatches] = useState([]);
  const [lines, setLines] = useState([]);
  const [feedback, setFeedback] = useState('');
  const linesContainerRef = useRef(null);

  const unitsLeft = [
    { id: 'dark-clouds', text: 'Dark clouds' },
    { id: 'soft-waves', text: 'Soft Waves' },
    { id: 'fresh-grass', text: 'Fresh grass' },
    { id: 'bright-flame', text: 'Bright flame' },
  ];

  const unitsRight = [
    { id: 'warm-fire', text: 'Warm fire' },
    { id: 'gray-mist', text: 'Gray mist' },
    { id: 'green-leaves', text: 'Green leaves' },
    { id: 'blue-lake', text: 'Blue lake' },
  ];

  const correctMatches = {
    'bright-flame': 'warm-fire',
    'dark-clouds': 'gray-mist',
    'fresh-grass': 'green-leaves',
    'soft-waves': 'blue-lake',
  };

  const handleDrop = (leftId, rightId) => {
    setMatches((prevMatches) => {
      const updatedMatches = prevMatches.filter(match => match.from !== leftId && match.to !== rightId);
      return [...updatedMatches, { from: leftId, to: rightId }];
    });
  };

  useEffect(() => {
    setLines(calculateLinePositions(matches, linesContainerRef));
  }, [matches]);

  const verifyMatches = () => {
    if (matches.length !== unitsLeft.length) {
      setFeedback('All units must be matched.');
      return;
    }

    const correct = matches.every(
      (match) => correctMatches[match.from] === match.to
    );
    setFeedback(correct ? 'All matches are correct!' : 'Try again, one or more matches are incorrect.');
  };

  const clearMatches = () => {
    setMatches([]);
    setFeedback('');
    setLines([]);
  };

  return (
    <div className={SemanticStyles.matchingSection} ref={linesContainerRef} style={{ position: 'relative' }}>
      <h4>Two-word Units</h4>
      <div className={SemanticStyles.matchingUnitsContainer}>
        <div className={SemanticStyles.unitsColumn}>
          {unitsLeft.map((unit) => (
            <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="left" handleDrop={handleDrop} />
          ))}
        </div>
        <div className={SemanticStyles.unitsColumn}>
          {unitsRight.map((unit) => (
            <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="right" handleDrop={handleDrop} />
          ))}
        </div>
      </div>
      {/* Render the lines */}
      <svg className={SemanticStyles.lines} width="100%" height="100%">
        {lines.map((line, index) => (
          <line key={index} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} stroke="black" strokeWidth="2" />
        ))}
      </svg>
      <div className={SemanticStyles.buttons}>
        <button onClick={verifyMatches} className={SemanticStyles.verifyButton}>Check</button>
        <button onClick={clearMatches} className={SemanticStyles.clearButton}>Clear</button>
      </div>
      {feedback && <p className={SemanticStyles.feedback}>{feedback}</p>}
    </div>
  );
};


// Three-Word Matching Section
const ThreeWordMatchingSection = () => {
  const [matches, setMatches] = useState([]);
  const [lines, setLines] = useState([]);
  const [feedback, setFeedback] = useState('');
  const linesContainerRef = useRef(null);

  const unitsLeft = [
    { id: 'fresh-white-snow', text: 'Fresh white snow' },
    { id: 'tall-brown-hill', text: 'Tall Brown hill' },
    { id: 'soft-brown-earth', text: 'Soft brown earth' },
  ];

  const unitsRight = [
    { id: 'rich-dark-soil', text: 'Rich dark soil' },
    { id: 'wide-green-plain', text: 'Wide green plain' },
    { id: 'frail-laced-frost', text: 'Frail laced frost' },
  ];

  const correctMatches = {
    'soft-brown-earth': 'rich-dark-soil',
    'tall-brown-hill': 'wide-green-plain',
    'fresh-white-snow': 'frail-laced-frost',
  };

  const handleDrop = (leftId, rightId) => {
    setMatches((prevMatches) => {
      const updatedMatches = prevMatches.filter(match => match.from !== leftId && match.to !== rightId);
      return [...updatedMatches, { from: leftId, to: rightId }];
    });
  };

  useEffect(() => {
    setLines(calculateLinePositions(matches, linesContainerRef));
  }, [matches]);

  const verifyMatches = () => {
    if (matches.length !== unitsLeft.length) {
      setFeedback('All units must be matched.');
      return;
    }

    const correct = matches.every(
      (match) => correctMatches[match.from] === match.to
    );
    setFeedback(correct ? 'All matches are correct!' : 'Try again, one or more matches are incorrect.');
  };

  const clearMatches = () => {
    setMatches([]);
    setFeedback('');
    setLines([]);
  };

  return (
    <div className={SemanticStyles.matchingSection} ref={linesContainerRef} style={{ position: 'relative' }}>
      <h4>Three-word Units</h4>
      <div className={SemanticStyles.matchingUnitsContainer}>
        <div className={SemanticStyles.unitsColumn}>
          {unitsLeft.map((unit) => (
            <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="left" handleDrop={handleDrop} />
          ))}
        </div>
        <div className={SemanticStyles.unitsColumn}>
          {unitsRight.map((unit) => (
            <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="right" handleDrop={handleDrop} />
          ))}
        </div>
      </div>
      {/* Render the lines */}
      <svg className={SemanticStyles.lines} width="100%" height="100%">
        {lines.map((line, index) => (
          <line key={index} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} stroke="black" strokeWidth="2" />
        ))}
      </svg>
      <div className={SemanticStyles.buttons}>
        <button onClick={verifyMatches} className={SemanticStyles.verifyButton}>Check</button>
        <button onClick={clearMatches} className={SemanticStyles.clearButton}>Clear</button>
      </div>
      {feedback && <p className={SemanticStyles.feedback}>{feedback}</p>}
    </div>
  );
};


const MultipleChoiceQuiz = ({ question, options, correctAnswer, handleComplete }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState('');
  const cardRef = useRef(null);

  const handleOptionClick = (index) => {
    setSelectedOption(index);
  };

  const verifyAnswer = () => {
    const isCorrect = selectedOption === correctAnswer;

    setFeedback(isCorrect ? 'Correct answer!' : 'Incorrect answer. Try again.');

    if (isCorrect) {
      if (cardRef.current) {
        cardRef.current.classList.add(SemanticStyles.correct);
      }
      setTimeout(() => {
        if (cardRef.current) {
          cardRef.current.classList.add(SemanticStyles.slideOut);
        }
        setTimeout(() => {
          handleComplete();
          setSelectedOption(null);
          setFeedback('');
          if (cardRef.current) {
            cardRef.current.classList.remove(SemanticStyles.correct, SemanticStyles.slideOut);
          }
        }, 500);
      }, 1000);
    } else {
      if (cardRef.current) {
        cardRef.current.classList.add(SemanticStyles.incorrect);
      }
      setTimeout(() => {
        if (cardRef.current) {
          cardRef.current.classList.remove(SemanticStyles.incorrect);
        }
        setSelectedOption(null);
      }, 1000);
    }
  };

  return (
    <div ref={cardRef} className={SemanticStyles.quizCard}>
      <h3>{question}</h3>
      <div className={SemanticStyles.options}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${SemanticStyles.option} ${selectedOption === index ? SemanticStyles.selected : ''}`}
            onClick={() => handleOptionClick(index)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className={SemanticStyles.buttons}>
        <button onClick={verifyAnswer} className={SemanticStyles.verifyButton}>Check</button>
      </div>
      {feedback && <p className={SemanticStyles.feedback}>{feedback}</p>}
    </div>
  );
};

const FillInTheBlanksQuiz = ({ handleComplete }) => {
  const initialBlanks = {
    blank1: '',
    blank2: '',
    blank3: '',
  };

  const correctAnswers = {
    blank1: ['cold march'],
    blank2: ['soft reeds grow'],
    blank3: ['faint stars'],
  };

  const [blanks, setBlanks] = useState(initialBlanks);
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  const units = [
    'cold march', 'faint stars', 'soft reeds grow', 'flows on', 'bronze bell', 'soft wet stones', 'dry sand'
  ];

  const handleBlankDrop = (item, blankKey) => {
    setBlanks((prevBlanks) => ({ ...prevBlanks, [blankKey]: item.text }));
  };

  const handleDragStart = (e, id, text) => {
    e.dataTransfer.setData('text', JSON.stringify({ id, text }));
  };

  const verifyAnswers = () => {
    const correct = Object.keys(blanks).every(
      (blankKey) => correctAnswers[blankKey].includes(blanks[blankKey])
    );

    setFeedback(correct ? 'All answers are correct!' : 'Some answers are incorrect. Try again.');
    setIsCorrect(correct);

    if (correct) {
      setTimeout(() => {
        const cardElement = document.querySelector(`.${SemanticStyles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(SemanticStyles.slideOut);
        }
        setTimeout(() => {
          handleComplete();
          setBlanks(initialBlanks);
          setFeedback('');
          setIsCorrect(false);
          if (cardElement) {
            cardElement.classList.remove(SemanticStyles.slideOut);
          }
        }, 500);
      }, 1000);
    }
  };

  return (
    <div className={`${SemanticStyles.quizCard} ${isCorrect ? SemanticStyles.correct : ''}`}>
      <h3>Fill in the Blanks</h3>
      <div className={SemanticStyles.quizLine}>
        <Blank blankKey="blank1" text={blanks.blank1} handleBlankDrop={handleBlankDrop} /> 
        new moon 
        <Blank blankKey="blank2" text={blanks.blank2} handleBlankDrop={handleBlankDrop} />
      </div>
      <div className={SemanticStyles.quizLine}>
        chilled breeze 
        <Blank blankKey="blank3" text={blanks.blank3} handleBlankDrop={handleBlankDrop} />
        young blooms glow
      </div>
      <div className={SemanticStyles.optionsContainer}>
        {units.map((unit) => (
          <DraggableUnit key={unit} id={unit} text={unit} onDragStart={handleDragStart} />
        ))}
      </div>
      <div className={SemanticStyles.buttons}>
        <button onClick={verifyAnswers} className={SemanticStyles.verifyButton}>Check</button>
        <button onClick={() => setBlanks(initialBlanks)} className={SemanticStyles.clearButton}>Clear</button>
      </div>
      {feedback && <p className={SemanticStyles.feedback}>{feedback}</p>}
    </div>
  );
};


const DraggableUnit = ({ id, text, onDragStart }) => {
  return (
    <div
      className={SemanticStyles.unitContainer}
      draggable
      onDragStart={(e) => onDragStart(e, id, text)}
    >
      {text}
    </div>
  );
};

const Blank = ({ blankKey, text, handleBlankDrop }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text'));
    handleBlankDrop(data, blankKey);
  };

  // Determine if it's a two-word or three-word blank
  const isThreeWord = blankKey === 'blank2'; // Adjust this condition as needed

  return (
    <div className={SemanticStyles.blankContainer}>
      <div
        className={`${SemanticStyles.dropBox} ${isThreeWord ? SemanticStyles.threeWordDropBox : SemanticStyles.twoWordDropBox}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <span className={SemanticStyles.blank}>
          {text || '____'}
        </span>
      </div>
    </div>
  );
};



const QuizSet = () => {
  const [currentQuiz, setCurrentQuiz] = useState(0);

  const quizzes = [
    {
      question: "Choose a two-word unit that has a similar shared meaning (resonance) as 'faint stars'",
      options: ["Light rain", "Cool breeze", "Deep lake", "Dim moon"],
      correctAnswer: 3, // Index of the correct answer in the options array
    },
    {
      question: "Choose a two-word unit that has a similar shared meaning (resonance) as 'tall trees'",
      options: ["Strong storm", "Thick grass", "Bronze bell", "Sharp stones"],
      correctAnswer: 1,
    },
    {
      question: "Choose a two-word unit that has a similar shared meaning (resonance) as 'warm dawn'",
      options: ["Wide sea", "White sails", "New sun", "Wet path"],
      correctAnswer: 2,
    },
  ];

  const handleComplete = () => {
    setCurrentQuiz((prevQuiz) => prevQuiz + 1);
  };

  return (
    <div className={SemanticStyles.quizContainer}>
      {currentQuiz < quizzes.length ? (
        <MultipleChoiceQuiz
          question={quizzes[currentQuiz].question}
          options={quizzes[currentQuiz].options}
          correctAnswer={quizzes[currentQuiz].correctAnswer}
          handleComplete={handleComplete}
        />
      ) : currentQuiz === quizzes.length ? (
        <FillInTheBlanksQuiz handleComplete={handleComplete} />
      ) : (
        <p>All quizzes completed!</p>
      )}
    </div>
  );
};

const SemanticParallelism = () => {
  const [showDetails, setShowDetails] = useState({
    explanation: false,
    example: false,
  });

  const toggleDetails = (detail) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [detail]: !prevDetails[detail],
    }));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={SemanticStyles.container}>
        <h2 className={SemanticStyles.title}>Learn Semantic Parallelism in Jueju</h2>

        {/* Explanation Section */}
        <section className={SemanticStyles.explanationSection}>
          <h3 onClick={() => toggleDetails('explanation')} className={SemanticStyles.clickable}>
            What is Semantic Rhyme/Parallelism in Jueju?
          </h3>
          {showDetails.explanation && (
            <>
              <p>
                In English Jueju, the first couplet of the poem (the first and second lines) must demonstrate semantic parallelism. This means that the corresponding units within these lines should mirror each other in meaning, structure, or imagery. This mirroring creates a balance and harmony in the poem, highlighting the relationships between ideas or elements being described.
              </p>
              <p>
                Each line in the first couplet is divided into units (either 2-word or 3-word units), and the expectation is that the corresponding units across these two lines will be semantically parallel. <br></br>
                <br></br>
                This parallelism can take different forms, such as direct similarity, antithetical contrast, cause and effect, or tonal resonance. However, regardless of whether a semantically rhyming/parallel units are similar or antithetical, they should both exists within a same category of meaning. For example two things related to weather, or two things that have to do with sky or plants or human settlements etc.
              </p>
            </>
          )}
        </section>
        <p> 
    To better understand semantic rhyming, watch the video below: 
  </p>
  <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/S3I7j2jc2aw" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowfullscreen>
  </iframe>
        <br></br>

        {/* Additional Explanation Section with Example */}
        <section className={SemanticStyles.explanationSection}>
          <h3 onClick={() => toggleDetails('example')} className={SemanticStyles.clickable}>
            Example: Semantic Rhyme/Parallelism in a Jueju Couplet
          </h3>
          {showDetails.example && (
            <>
              <p>
                Let's examine the following couplet:
                <br></br>
                <br></br>
              </p>
              <p className={SemanticStyles.example}>
                "Swift brook &nbsp;&nbsp;&nbsp;&nbsp;thin reeds &nbsp;&nbsp;&nbsp;&nbsp;dark sets cold<br></br>
                "Slow breeze&nbsp;&nbsp;&nbsp;&nbsp; thick wheat &nbsp;&nbsp;&nbsp;&nbsp;dusk spreads gold"
              </p>
              <h4>Analysis of the Corresponding Units:</h4>
              <ul>
                <li>
                  <strong>Unit 1: "Swift brook" / "Slow breeze":</strong> 
                  These units are <strong> semantically parallel</strong>. They contrast movement in nature. "Swift" and "Slow" balance each other, describing water and wind in harmony.
                </li>
                <li>
                  <strong>Unit 2: "thin reeds" / "thick wheat":</strong> 
                  These units are <strong>semantically parallel</strong>. Both refer to vegetation but differ in texture. The contrast between "thin" and "thick" enhances the imagery while maintaining the natural theme.
                </li>
                <li>
                  <strong>Unit 3: "dark sets cold" / "dusk spreads gold":</strong> 
                  These units are also <strong>semantically parallel</strong>. They depict changes in light and temperature. "Dark" and "cold" contrast with "dusk" and "gold," creating balance.
                </li>
              </ul>
      
              
              <p>
                 Using parallelism in the first couplet helps the poet describe a single scene at various scales or through various senses all in a single moment. Unlike many english poems, regulated jueju do not tell a story, but express feelings and ideas through this single, deeply preceived moment in time. Semantic and Grammatical parallelism suspends time.
              </p>
            </>
          )}
        </section>

        {/* Matching Sections */}
        <section className={SemanticStyles.explanationSection}>
          <h3>Match the Units: Semantic Parallelism</h3>
           <div className={SemanticStyles.matchingSectionsContainer}>
          <TwoWordMatchingSection />
          <ThreeWordMatchingSection />
          </div>
        </section>

        {/* Quiz Set Section */}
        <section className={SemanticStyles.explanationSection}>
          <h3>Quiz Set: Test Your Knowledge</h3>
          <QuizSet />
        </section>
      </div>
    </DndProvider>
  );
};

export default SemanticParallelism;

