import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Resetstyles from './ResetPassword.module.css'; // Import the new CSS file

function ResetPassword() {
  const API_URL = 'https://juejupath.com/api';
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    try {
      await axios.post(`${API_URL}/user/reset-password/?token=${encodeURIComponent(token)}&new_password=${encodeURIComponent(newPassword)}`);
      setMessage('Password reset successful. Redirecting to login...');
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      setMessage(
        typeof error.response?.data?.detail === 'string' 
          ? error.response?.data?.detail 
          : 'Failed to reset password.'
      );
    }
  };

  return (
    <div className={Resetstyles.resetPasswordContainer}>
      <div className={Resetstyles.resetPasswordForm}>
        <h2 className={Resetstyles.resetPasswordTitle}>Reset Your Password</h2>
        <input 
          type="password" 
          className={Resetstyles.resetPasswordInput} 
          placeholder="New Password" 
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)} 
          required 
        />
        <button className={Resetstyles.resetPasswordButton} onClick={handlePasswordReset}>
          Reset Password
        </button>
        <p className={`${Resetstyles.resetPasswordMessage} ${message.includes("Failed") ? Resetstyles.error : ""}`}>
          {message}
        </p>
      </div>
    </div>
  );
}

export default ResetPassword;

