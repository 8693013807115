// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Assess from './components/Assess';
import Learn from './components/Learn';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/Dashboard';
import AboutJueju from './components/AboutJueju';
import AboutWebsite from './components/AboutWebsite';
import AboutEnglishJueju from './components/AboutEnglishJueju';
import RegLearn from './components/RegLearn';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import TeacherDashboard from './components/Teacher';
import ResetPassword from './components/ResetPassword'; 
import './App.css';

function App() {
  useEffect(() => {
    // Any additional logic can go here if needed
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route 
            path="/assess" 
            element={
              <ProtectedRoute>
                <Assess />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/learn" 
            element={
              <ProtectedRoute>
                <Learn />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/regulated" 
            element={
              <ProtectedRoute>
                <RegLearn />
              </ProtectedRoute>
            } 
          />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route 
            path="/about/jueju" 
            element={
              <ProtectedRoute>
                <AboutJueju />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/about/english-jueju" 
            element={
              <ProtectedRoute>
                <AboutEnglishJueju />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/about/website" 
            element={
              <ProtectedRoute>
                <AboutWebsite />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/learn/examination-path" 
            element={
              <ProtectedRoute>
                <Learn />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/teacher/dashboard" 
            element={
              <ProtectedRoute>
                <TeacherDashboard />
              </ProtectedRoute>
            } 
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


