import React, { useState, useEffect } from 'react';
import axios from 'axios';
import base64 from 'base64-js';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const AdminInterface = () => {
  const [activeTab, setActiveTab] = useState('viewUsers');
  const [users, setUsers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [regularUsers, setRegularUsers] = useState([]);
  const [students, setStudents] = useState([]); // Holds students of a selected teacher
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [manualSubmissions, setManualSubmissions] = useState({ pending: [], finished: [] });
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [error, setError] = useState('');
  const [finalSubmissions, setFinalSubmissions] = useState([]);
  const [userSubmissions, setUserSubmissions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);


  const [unviewedCount, setUnviewedCount] = useState(0);
  const [feedbackCompletedCount, setFeedbackCompletedCount] = useState(0);
  const [stats, setStats] = useState({
    users: { total: 0, regular: 0, teachers: 0, students: 0 },
    manual_submissions: { total: 0, pending: 0, completed: 0 },
    newman_submissions: { total: 0 },
  });
  

  const getToken = () => {
    return localStorage.getItem('adminToken');
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/stats/`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setStats(response.data);
    } catch (error) {
      setError("Failed to fetch stats: " + error);
    }
  };
  
  useEffect(() => {
    fetchStats();
  }, []);
  

  const fetchFinalSubmissions = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/final-submissions/`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setFinalSubmissions(response.data);
    } catch (error) {
      setError("Failed to fetch final submissions: " + error);
    }
  };
  useEffect(() => {
    if (activeTab === "finalSubmissions") {
      fetchFinalSubmissions();
    }
  }, [activeTab]);
    
  const fetchUserSubmissions = async (userId, userName) => {
    try {
      const response = await axios.get(`${API_URL}/admin/user/${userId}/submissions`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setUserSubmissions(response.data);
      setSelectedUser(userName);
      setActiveTab('userSubmissions'); // Navigate to the user submissions tab
    } catch (error) {
      setError('Failed to fetch submissions for the user: ' + error);
    }
  };
  
  
  const fetchStudentsForTeacher = async (groupName, teacherName) => {
    try {
      const response = await axios.get(`${API_URL}/admin/teacher/${groupName}/students`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setStudents(response.data);
      setSelectedTeacher(teacherName);
    } catch (error) {
      setError('Failed to fetch students for this group: ' + error);
      setStudents([]);
    }
  };
  
  
  
  
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/users/`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
  
        const teachersData = response.data.filter((user) => user.type === "teacher");
        const studentsData = response.data.filter((user) => user.type === "student");
        const regularUsersData = response.data.filter((user) => user.type === "regular");
  
        // Group students under their respective teachers
        const teachersWithStudents = teachersData.map((teacher) => ({
          ...teacher,
          students: studentsData.filter(
            (student) => student.group_name === teacher.group_name
          ),
        }));
  
        setTeachers(teachersWithStudents);
        setRegularUsers(regularUsersData);
      } catch (error) {
        setError("Failed to fetch users: " + error);
      }
    };
    
    const fetchManualSubmissions = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/manual-submissions/`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
    
        const pendingSubmissions = response.data.filter((s) => s.status === "pending");
        const finishedSubmissions = response.data.filter((s) => s.status === "finished");
    
        setManualSubmissions({ pending: pendingSubmissions, finished: finishedSubmissions });
      } catch (error) {
        setError('Failed to fetch manual submissions: ' + error);
      }
    };
    

    fetchUsersData();
    fetchManualSubmissions();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedTeacher(null);
    setStudents([]);
    setSelectedSubmission(null);
    setFeedbackText('');
  };

  const handleProvideFeedback = async (submissionId) => {
    try {
      await axios.post(`${API_URL}/admin/manual-submissions/feedback/`, {
        submission_id: submissionId,
        feedback: feedbackText,
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      alert('Feedback submitted successfully');
      setSelectedSubmission(null);
      setFeedbackText('');
    } catch (error) {
      setError('Failed to submit feedback: ' + error);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Admin Dashboard</h1>
      <header style={styles.header}>
        <div style={styles.stats}>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Total Users</h3>
            <p style={styles.statNumber}>{stats.users.total}</p>
            <p>
              Regular: {stats.users.regular} Teachers: {stats.users.teachers} Students:{" "}
              {stats.users.students}
            </p>
          </div>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Manual Submissions</h3>
            <p style={styles.statNumber}>
              {stats.manual_submissions.pending} / {stats.manual_submissions.total} Pending
            </p>
            <p>Completed: {stats.manual_submissions.completed}</p>
          </div>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Newman Prize Submissions</h3>
            <p style={styles.statNumber}>{stats.newman_submissions.total}</p>
          </div>
        </div>
      </header>

      {error && <p style={styles.error}>{error}</p>}

      <div style={styles.tabs}>
        <button
          style={{ ...styles.tabButton, ...(activeTab === 'viewUsers' && styles.activeTab) }}
          onClick={() => handleTabChange('viewUsers')}
        >
          View Users
        </button>
        <button
          style={{ ...styles.tabButton, ...(activeTab === 'manualSubmissions' && styles.activeTab) }}
          onClick={() => handleTabChange('manualSubmissions')}
        >
          Manual Submissions
        </button>
        <button
        style={{ ...styles.tabButton, ...(activeTab === "finalSubmissions" && styles.activeTab) }}
        onClick={() => handleTabChange("finalSubmissions")}
        >
        Final Submissions
        </button>

      </div>

      {activeTab === 'viewUsers' && (
  <div style={styles.contentContainer}>
    {/* Teachers Section */}
<div style={styles.sectionContainer}>
  <h3 style={styles.sectionTitle}>Teachers and Their Students</h3>
  <div style={styles.cardGrid}>
    {teachers.map((teacher) => (
      <div key={teacher._id} style={styles.teacherCard}>
        {/* Teacher Details */}
        <h4 style={styles.teacherTitle}>{teacher.name}</h4>
        <p><strong>Email:</strong> {teacher.email || "N/A"}</p>
        <p><strong>School:</strong> {teacher.school_name || "N/A"}</p>
        <p><strong>Group:</strong> {teacher.group_name || "N/A"}</p>

        {/* Students Section */}
        <div style={styles.studentsContainer}>
          <h5 style={styles.sectionSubtitle}>Students:</h5>
          {teacher.students.length > 0 ? (
            teacher.students.map((student) => (
              <div key={student._id} style={styles.studentCard}>
                <h5 style={styles.studentTitle}>{student.username}</h5>
                <p><strong>Rank:</strong> {student.rank || "Unranked"}</p>
                <p><strong>Group:</strong> {student.group_name || "N/A"}</p>
                <button
                  style={styles.button}
                  onClick={() => fetchUserSubmissions(student._id, student.username)}
                >
                  View Submissions
                </button>
              </div>
            ))
          ) : (
            <p style={styles.noDataText}>No students yet for this teacher's group.</p>
          )}
        </div>
      </div>
    ))}
  </div>
</div>

{/* Regular Users Section */}
<div style={styles.sectionContainer}>
  <h3 style={styles.sectionTitle}>Regular Users</h3>
  <div style={styles.cardGrid}>
    {regularUsers.map((user) => (
      <div key={user.email} style={styles.userCard}>
        <h4 style={styles.userTitle}>{user.name}</h4>
        <p><strong>Email:</strong> {user.email || "N/A"}</p>
        <p><strong>Rank:</strong> {user.rank || "None"}</p>
        <button
          style={styles.button}
          onClick={() => fetchUserSubmissions(user._id, user.name)}
        >
          View Submissions
        </button>
      </div>
    ))}
  </div>
</div>

  </div>
)}

{activeTab === 'userSubmissions' && (
  <div style={styles.contentContainer}>
    <h3 style={styles.sectionTitle}>
      Submissions by {selectedUser}
    </h3>
    {userSubmissions.length === 0 ? (
      <p>No submissions found for this user.</p>
    ) : (
      Object.entries(
        userSubmissions.reduce((grouped, submission) => {
          const level = submission.submission_level || "Unknown";
          if (!grouped[level]) grouped[level] = [];
          grouped[level].push(submission);
          return grouped;
        }, {})
      ).map(([level, submissions]) => (
        <div key={level} style={styles.levelContainer}>
          <h4 style={styles.levelTitle}>{level} Submissions</h4>
          <div style={styles.cardGrid}>
            {submissions.map((submission) => (
              <div key={submission._id} style={styles.submissionCard}>
                <h4 style={styles.submissionTitle}>
                  {submission.submission_level} Submission
                  {submission.submission_level === "jinshi/final" && submission.poem_type && (
                    <span style={styles.poemType}> ({submission.poem_type})</span>
                  )}
                </h4>
                <p><strong>Status:</strong> {submission.status || "Unknown"}</p>
                <p><strong>Submitted At:</strong> {new Date(submission.submitted_at).toLocaleString()}</p>
                <p><strong>Type:</strong> {submission.submission_type}</p>
                <p><strong>Submitted Content:</strong></p>
                <div style={styles.submissionContent}>
                  {submission.couplet ? (
                    // Couplet Display
                    <div>
                      {submission.couplet.split("\n").map((line, index) => (
                        <p key={index} style={styles.poemLine}>{line}</p>
                      ))}
                    </div>
                  ) : (
                    // Poem Display
                    submission.poem && (
                      <div>
                        {submission.poem.split("\n").map((line, index) => (
                          <p key={index} style={styles.poemLine}>{line}</p>
                        ))}
                      </div>
                    )
                  )}
                </div>

                {/* View Feedback Button */}
                <button
                  style={styles.button}
                  onClick={() =>
                    setSelectedFeedback(submission.model_assessment || "No feedback provided yet.")
                  }
                >
                  View Feedback
                </button>
              </div>
            ))}
          </div>
        </div>
      ))
    )}

    <button
      style={styles.button}
      onClick={() => handleTabChange('viewUsers')}
    >
      Back to Users
    </button>
  </div>
)}

{/* Feedback Modal */}
{selectedFeedback && (
  <div style={styles.feedbackModal}>
    <div style={styles.feedbackContent}>
      <h3>Feedback</h3>
      <p style={styles.feedbackText}>{selectedFeedback}</p>
      <button
        style={styles.button}
        onClick={() => setSelectedFeedback(null)}
      >
        Close
      </button>
    </div>
  </div>
)}


{activeTab === 'manualSubmissions' && (
  <div style={styles.contentContainer}>
    {/* Pending Submissions */}
    <h3 style={styles.sectionTitle}>Pending Submissions</h3>
    {manualSubmissions.pending.length === 0 ? (
      <p>No pending submissions.</p>
    ) : (
      <div style={styles.cardGrid}>
        {manualSubmissions.pending.map((submission) => (
          <div key={submission._id} style={styles.submissionCard}>
            <p><strong>User Name:</strong> {submission.user_name || 'Unknown'}</p>
            <p><strong>User Level:</strong> {submission.user_level || 'Unranked'}</p>
            <p><strong>User Type:</strong> {submission.user_type || 'Unknown'}</p>
            <p><strong>submission_level:</strong> {submission.submission_level || 'unknown'}</p>
            <p><strong>Submitted Content:</strong></p>
            <div style={styles.submissionContent}>
              {submission.couplet ? (
                <div>
                  {submission.couplet.split("\n").map((line, index) => (
                    <p key={index} style={styles.poemLine}>{line}</p>
                  ))}
                </div>
              ) : (
                submission.poem && (
                  <div>
                    {submission.poem.split("\n").map((line, index) => (
                      <p key={index} style={styles.poemLine}>{line}</p>
                    ))}
                  </div>
                )
              )}
            </div>
            <button
              style={styles.button}
              onClick={() => setSelectedSubmission(submission)}
            >
              Provide Feedback
            </button>
          </div>
        ))}
      </div>
    )}

    {/* Finished Submissions */}
    <h3 style={styles.sectionTitle}>Finished Submissions</h3>
    {manualSubmissions.finished.length === 0 ? (
      <p>No finished submissions.</p>
    ) : (
      <div style={styles.cardGrid}>
        {manualSubmissions.finished.map((submission) => (
          <div key={submission._id} style={styles.submissionCard}>
            <p><strong>User Name:</strong> {submission.user_name || 'Unknown'}</p>
            <p><strong>User Level:</strong> {submission.user_level || 'Unranked'}</p>
            <p><strong>User Type:</strong> {submission.user_type || 'Unknown'}</p>
            <p><strong>submission_level:</strong> {submission.submission_level || 'unknown'}</p>
            <p><strong>Submitted Content:</strong></p>
            <div style={styles.submissionContent}>
              {submission.couplet ? (
                <div>
                  {submission.couplet.split("\n").map((line, index) => (
                    <p key={index} style={styles.poemLine}>{line}</p>
                  ))}
                </div>
              ) : (
                submission.poem && (
                  <div>
                    {submission.poem.split("\n").map((line, index) => (
                      <p key={index} style={styles.poemLine}>{line}</p>
                    ))}
                  </div>
                )
              )}
            </div>
            <p><strong>Feedback:</strong> {submission.feedback || 'No feedback provided'}</p>
          </div>
        ))}
      </div>
    )}
  </div>
)}


{selectedSubmission && (
  <div style={styles.feedbackModal}>
    <div style={styles.feedbackContent}>
      <h3>Provide Feedback</h3>
      <p>
        <strong>{selectedSubmission.couplet ? 'Couplet' : 'Poem'}:</strong> {selectedSubmission.couplet || selectedSubmission.poem}
      </p>
      <textarea
        style={styles.feedbackTextArea}
        placeholder="Enter your feedback here..."
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
      />
      <button
        style={styles.button}
        onClick={() => handleProvideFeedback(selectedSubmission._id)}
      >
        Submit Feedback
      </button>
      <button
        style={styles.button}
        onClick={() => setSelectedSubmission(null)}
      >
        Cancel
      </button>
    </div>
  </div>
)}

{activeTab === "finalSubmissions" && (
  <div style={styles.contentContainer}>
    <h3 style={styles.sectionTitle}>Final Submissions</h3>
    {finalSubmissions.length === 0 ? (
      <p>No final submissions available.</p>
    ) : (
      <div style={styles.cardGrid}>
        {finalSubmissions.map((submission) => (
          <div key={submission._id} style={styles.submissionCard}>
            <h4 style={styles.submissionTitle}>{submission.title || 'Final Submission'}</h4>
            
            <p><strong>Poem Type:</strong> {submission.poem_type || 'Unknown'}</p>
            <p><strong>User Type:</strong> {submission.user_type || 'Unknown'}</p>
            <p><strong>User Level:</strong> {submission.user_level || 'Unranked'}</p>

            {submission.user_type === "student" ? (
              <>
                <p><strong>Username:</strong> {submission.username || "Unknown"}</p>
                <p><strong>Group Name:</strong> {submission.group_name || "N/A"}</p>
                <p><strong>Teacher:</strong> {submission.teacher_name || "Unknown"}</p>
                <p><strong>School:</strong> {submission.school_name || "N/A"}</p>
              </>
            ) : (
              <p><strong>User Name:</strong> {submission.user_name || 'Unknown'}</p>
            )}

            <p><strong>Submitted Content:</strong></p>
            <div style={styles.submissionContent}>
              {submission.submitted_content.split("\n").map((line, index) => (
                <p key={index} style={styles.poemLine}>{line}</p>
              ))}
            </div>

            <p><strong>Submitted At:</strong> {new Date(submission.submitted_at).toLocaleString()}</p>

            {submission.feedback && (
              <p><strong>Feedback:</strong> {submission.feedback}</p>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
)}




{selectedFeedback && (
  <div style={styles.feedbackModal}>
    <div style={styles.feedbackContent}>
      <h3>Feedback</h3>
      <p style={styles.feedbackText}>{selectedFeedback}</p>
      <button
        style={styles.button}
        onClick={() => setSelectedFeedback(null)}
      >
        Close
      </button>
    </div>
  </div>
)}
    </div>
  );
};

const styles = {
  sectionContainer: {
    marginBottom: '30px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '15px',
  },
  
  studentsContainer: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  },
  
  sectionTitle: {
    fontSize: '1.5em',
    marginBottom: '15px',
    color: '#3d405b',
    textAlign: 'left',
  },
  sectionSubtitle: {
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#4a4e69',
    marginBottom: '10px',
  },
  
  userTitle: {
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '1.2em',
    color: '#4a4e69',
  },
  
  noDataText: {
    color: '#777',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
    padding: '20px',
  },
  title: {
    fontSize: '2em',
    marginBottom: '10px',
    color: '#3d405b',
  },
  header: {
    width: '100%',
    backgroundColor: '#3d405b',
    color: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '30px',
    textAlign: 'center',
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  statCard: {
    backgroundColor: '#6a7592',
    color: '#ffffff',
    padding: '15px 25px',
    borderRadius: '8px',
    width: '180px',
    textAlign: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
  },
  statTitle: {
    fontSize: '1.1em',
    fontWeight: '600',
    color: '#ffffff',
  },
  statNumber: {
    fontSize: '1.6em',
    fontWeight: '700',
    color: '#c9d1da',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  tabButton: {
    backgroundColor: '#4a4e69',
    color: 'white',
    border: 'none',
    padding: '12px 25px',
    margin: '0 10px',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: 'background 0.3s',
  },
  activeTab: {
    backgroundColor: '#9a8c98',
  },
  contentContainer: {
    display: 'inline-block',
    width: '100%',
    maxWidth: '1000px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
  },
  userCard: {
    backgroundColor: '#f7f5f2',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    textAlign: 'left',
  },
  teacherCard: {
    backgroundColor: '#eaecef',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    position: 'relative',
  },
  
  teacherTitle: {
    fontSize: '1.4em',
    fontWeight: '600',
    color: '#2c3e50',
    marginBottom: '10px',
  },
  
  studentsContainer: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  },
  
  studentCard: {
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
    border: '1px solid #ddd',
  },
  
  studentTitle: {
    fontSize: '1.1em',
    fontWeight: '500',
    color: '#34495e',
    marginBottom: '8px',
  },
  
  noDataText: {
    color: '#777',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  
  submissionCard: {
    backgroundColor: '#f7f5f2',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    textAlign: 'left',
    transition: 'transform 0.3s',
    display: 'flex',
    flexDirection: 'column',
  },
  submissionTitle: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#3d405b',
  },
  button: {
    backgroundColor: '#9a8c98',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    margin: '5px 0',
  },
  poemType: {
    fontSize: '0.9em',
    fontWeight: 'normal',
    color: '#6a7592',
  },
  submissionContent: {
    marginTop: '10px',
    padding: '15px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    whiteSpace: 'pre', // Preserve whitespace and ensure line breaks are honored
    overflowX: 'auto', // Add horizontal scroll if the content exceeds the width
    wordWrap: 'normal', // Prevent wrapping of words
    fontFamily: 'monospace', // Consistent font style for poetry
    fontSize: '1.1em',
  },
  levelContainer: {
    marginBottom: '30px',
  },
  levelTitle: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#333',
    marginBottom: '15px',
  },
  
  
  poemLine: {
    margin: '0', // No margin between lines
    lineHeight: '1.5', // Adjust line height for better readability
    color: '#34495e',
  },
  feedbackModal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  feedbackContent: {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    textAlign: 'center',
  },
  feedbackTextArea: {
    width: '100%',
    height: '100px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginTop: '10px',
    resize: 'vertical',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  submissionCard: {
    backgroundColor: "#f7f5f2",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s",
    textAlign: "left",
    marginBottom: "20px",
  },
  poemContent: {
    whiteSpace: 'pre-wrap', // Preserve line breaks and wrap text
    fontFamily: 'monospace', // Optional: Set a specific font for poetry
    fontSize: '1rem',
    color: '#333',
    marginTop: '10px',
    display: 'block',
  },
  feedbackText: {
    fontSize: '1rem',
    color: '#333',
    textAlign: 'left',
    margin: '10px 0',
  },
  
  
};

export default AdminInterface;
