import React, { useState } from 'react';
import styles from './JurenIntro.module.css';

const JurenIntro = () => {
  const [showPhilosophy, setShowPhilosophy] = useState(false);
  const [showDetails, setShowDetails] = useState({
    endRhyming: false,
    cosmologicalRhyming: false,
    parallelism: false,
    grammaticalParallelism: false,
    pingZe: false,
  });

  const toggleDetails = (detail) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [detail]: !prevDetails[detail],
    }));
  };

  return (
    <div className={styles.jurenIntroContainer}>
      <h2 className={styles.heading}>Juren Degree</h2>
      
      <p className={styles.introText}>
        Congratulations, you have reached the third level of the Examination Path, the Juren Degree (Recommended Scholar).
      </p>
      
      <p className={styles.introText}>
        In the previous exams, you mastered the first two levels of rhyming: 
        <strong> End Rhyming and Cosmological Rhyming: </strong> 
        the interplay between the natural world (jing) and the inner world of feelings and ideas (qing). In this exam, you'll learn to master the next three levels of rhyming:
      </p>

      {/* Stacking the new levels of rhyming on the old ones */}
      <div className={styles.ruleSection}>
        <h3 className={styles.subHeading}>Core Concepts of Rhyming: What Rhymes with what?</h3>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleDetails('endRhyming')} className={styles.sectionTitle}>
            End Rhyming {showDetails.endRhyming ? '▲' : '▼'}
          </h4>
          {showDetails.endRhyming && (
            <div className={styles.sectionContent}>
              <p>Poets must rhyme the last words in the first, second, and fourth lines (an AABA end rhyme scheme).</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleDetails('cosmologicalRhyming')} className={styles.sectionTitle}>
            Cosmological Rhyming {showDetails.cosmologicalRhyming ? '▲' : '▼'}
          </h4>
          {showDetails.cosmologicalRhyming && (
            <div className={styles.sectionContent}>
              <p>Discover the inter-resonance of nature and human consciousness (feelings, emotions, ideas, etc.) and disclose this in the relationship between the poem’s two couplets.</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleDetails('parallelism')} className={styles.sectionTitle}>
            Semantic Rhyming/Parallelism {showDetails.parallelism ? '▲' : '▼'}
          </h4>
          {showDetails.parallelism && (
            <div className={styles.sectionContent}>
              <p>Semantic parallelism involves balancing or counterbalancing the meaning of corresponding units in the poem's lines. This technique adds depth to the poem's structure.</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleDetails('grammaticalParallelism')} className={styles.sectionTitle}>
            Grammatical Rhyming/Parallelism {showDetails.grammaticalParallelism ? '▲' : '▼'}
          </h4>
          {showDetails.grammaticalParallelism && (
            <div className={styles.sectionContent}>
              <p>Grammatical parallelism ensures that the parts of speech in corresponding lines match, enhancing the poem's rhythm and flow.</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleDetails('pingZe')} className={styles.sectionTitle}>
            Vowel Quality Rhyming/Parallelism {showDetails.pingZe ? '▲' : '▼'}
          </h4>
          {showDetails.pingZe && (
            <div className={styles.sectionContent}>
              <p>Ping/Ze parallelism distinguishes between yin (short, clipped vowels) and yang (full, robust vowels) sounds, arranging them harmoniously in the poem.</p>
            </div>
          )}
        </div>
      </div>

      <p className={styles.introText}>
        At the end of this exam, you will be capable of mastering all five levels of rhyming and composing your first fully regulated couplet! Let's get started:
      </p>
      
      <button 
        className={styles.expandButton}
        onClick={() => setShowPhilosophy(!showPhilosophy)}
      >
        {showPhilosophy ? 'Hide' : 'Learn More'} About Rhyming and Inter-resonance
      </button>

      {showPhilosophy && (
        <div className={styles.quoteSection}>
          <p className={styles.detailText}>
            In the ancient book of Philosophy entitled The Luxuriant Dew of the Spring and Autumn Annals (Chunqiu fanlu 春秋繁露), attributed to the Confucian thinker, Dong Zhongshu 董仲舒 (179–104 BCE), he describes the underlying idea of cosmological rhyming or “inter-resonance” in a chapter entitled “Things of the same category move one another” (Tonglei xiangdong 同類相動)
          </p>
          <blockquote className={styles.quote}>
            <p className={styles.detailText}>
              故氣同則會，聲比則應，其驗然也。試調琴瑟而錯之，鼓其宮則他宮 應之，鼓其商而他商應之，五音比而自鳴，非有神，其數然也。
              <br />
              Thus, if qi are similar, they will merge; if sounds correspond [bi 比], they resonate [ying 應]. The test of this is thus. Try to tune a qin and a se [string instruments] and be off. If you pluck the gong [certain note] on one, then the gong on the other will respond to it; if you pluck the shang [another note] on one then the shang on the other will respond to it. The Five Notes, if they correspond, they will sound by themselves.
            </p>
          </blockquote>
          <p className={styles.detailText}>
            According to inter-resonance theory (ganying), therefore, phenomena emerge with properties that can be described in terms of their degrees of resonance("Tonglei同類相動"). The rules of rhyming or “parallelism” emphasize these relationships in nature and bring them into poetry.
          </p>
	  <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/b3TTxQVMkuc"
            title="YouTube video player"
            frameBorder="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </div>
  );
};

export default JurenIntro;

