import React, { useState, useEffect } from 'react';
import GongshiStyles from './GongshiIntro.module.css';

const GongshiIntro = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [filledPoem, setFilledPoem] = useState([
    "Moon light _____ _____ frost fills sky",
    "Sweet-gums boat lights _____ _____ _____",
    "Out side Su zhou, Han shan shrine",
    "_____ _____ bells ring sound close by",
  ]);

  const wordBank = ["crows caw", "wake I lie", "from boat", "cars honk"]; // Include incorrect option

  const steps = [
    { lineIndex: 0, blank: "_____ _____", word: "crows caw" }, // First line
    { lineIndex: 1, blank: "_____ _____ _____", word: "wake I lie" }, // Second line
    { lineIndex: 3, blank: "_____ _____", word: "from boat" }, // Fourth line
  ];

  useEffect(() => {
    if (currentStep < steps.length) {
      const { lineIndex, blank, word } = steps[currentStep];
      const newPoem = [...filledPoem];
      newPoem[lineIndex] = newPoem[lineIndex].replace(blank, word);
      setTimeout(() => {
        setFilledPoem(newPoem);
        setCurrentStep((prev) => prev + 1);
      }, 2000); // Adjust timing for each animation
    }
  }, [currentStep]);

  return (
    <div className={GongshiStyles.container}>
      <h1 className={GongshiStyles.title}>Gongshi Introduction</h1>
      <p className={GongshiStyles.intro}>
        🎉 Congratulations, you have mastered the regulated couplet! Now it is time to apply everything you have learned to master the highest form of classical Chinese poetry, regulated jueju.
      </p>
      <p className={GongshiStyles.intro}>
        In this level, you will not only learn how to follow the traditional <span className={GongshiStyles.highlight}>ping/ze patterns</span> for five and seven-word couplets, but you will also learn how to follow these patterns to create full regulated jueju (in both perfect and acceptably imperfect ways). Let’s begin.
      </p>

      <div className={GongshiStyles.infoSection}>
        <h2 className={GongshiStyles.subtitle}> Ping/Ze Patterns</h2>
        <p className={GongshiStyles.content}>
          Like regulated couplets, both five- and seven-word regulated jueju are sorted into two categories: <span className={GongshiStyles.highlight}>“ping start”</span> and <span className={GongshiStyles.highlight}>“ze start”</span> forms. Just as before, one must look to the second word in a two-word poetry unit to assess whether it is a ping or ze unit.
        </p>
        <p className={GongshiStyles.content}>
          To determine the ping-ze pattern of a poem:
        </p>
        <ol className={GongshiStyles.list}>
          <li>Count the number of words per line (<strong>five</strong> or <strong>seven</strong>).</li>
          <li>Determine if the second word of the first unit is <span className={GongshiStyles.highlight}>ping</span> or <span className={GongshiStyles.highlight}>ze</span>.</li>
        </ol>
        <p className={GongshiStyles.content}>
          If the second word in the line is <span className={GongshiStyles.highlight}>ping</span>, it’s a “ping start.” If it is <span className={GongshiStyles.highlight}>ze</span>, then it is a “ze-start” poem.
        </p>
      </div>

      <div className={GongshiStyles.exampleSection}>
        <h3 className={GongshiStyles.exampleTitle}> Example: Ze-Start Seven-Word Jueju</h3>
        <p className={GongshiStyles.content}>
          Here is a well-known example of a “ze-start seven-word jueju” entitled <em>Mooring at Maple (Sweetgum) Bridge</em>:
        </p>
        <div className={GongshiStyles.poemContainer}>
          <p className={GongshiStyles.poem}>枫桥夜泊</p>
          <br></br>
          <p className={GongshiStyles.poem}>月落 乌啼 霜满天</p>
          <p className={GongshiStyles.poem}>江枫 渔火 对愁眠</p>
          <p className={GongshiStyles.poem}>姑苏 城外 寒山寺</p>
          <p className={GongshiStyles.poem}>夜半 钟声 到客船</p>
        </div>
        <div className={GongshiStyles.romanizedContainer}>
          <p className={GongshiStyles.romanized}>
            Romanized in modern Mandarin:<br />
            <br></br>
            Fēng Qiáo Yè Bó<br />
            Yuè luò wū tí shuāng mǎn tiān,<br />
            Jiāng fēng yú huǒ duì chóu mián.<br />
            Gū Sū chéng wài Hán Shān sì,<br />
            Yè bàn zhōng shēng dào kè chuán.
          </p>
        </div>
      </div>

      <div className={GongshiStyles.demoSection}>
        <h3 className={GongshiStyles.exampleTitle}> Demonstration: Filling the Poem</h3>
        <div className={GongshiStyles.quiz}>
          {filledPoem.map((line, index) => (
            <p key={index} className={GongshiStyles.line}>
              {line}
            </p>
          ))}
          <div className={GongshiStyles.wordBank}>
            {wordBank.map((word, index) => (
              <div
                key={index}
                className={`${GongshiStyles.word} ${index === currentStep ? GongshiStyles.active : ""}`}
              >
                {word}
              </div>
            ))}
          </div>
        </div>
      </div>
     <iframe width="960" height="515" 
      src="https://www.youtube.com/embed/swf1re-PNPc" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
     </iframe>
    </div>
  );
};

export default GongshiIntro;

